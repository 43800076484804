export default function TopNavBarGradient() {
  return (
    <svg width="1616" height="60" viewBox="0 0 1616 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_0_25)">
        <path
          d="M350.294 517.328C351.909 450.374 365.373 383.6 388.299 318.155C509.744 -28.5299 888.814 -209.505 1233.65 -88.7086C1509.51 7.92833 1680.97 266.578 1677.34 543.325"
          stroke="url(#paint0_linear_0_25)"
          stroke-width="300"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_0_25"
          x="0.292969"
          y="-476.179"
          width="2027.1"
          height="1369.5"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_0_25" />
        </filter>
        <linearGradient
          id="paint0_linear_0_25"
          x1="1476.73"
          y1="516.83"
          x2="64.8157"
          y2="23.741"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#002C63" />
          <stop offset="1" stop-color="#00E5BA" />
        </linearGradient>
      </defs>
    </svg>
  )
}
