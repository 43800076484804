import React from 'react';

export default function OrgSwitchIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.62475 9.6029L0.999778 5.7073C0.915984 5.65745 0.846561 5.58667 0.798363 5.50191C0.750164 5.41716 0.724854 5.32136 0.724854 5.22386C0.724854 5.12636 0.750164 5.03052 0.798363 4.94577C0.846561 4.86102 0.915984 4.79027 0.999778 4.74043L7.62475 0.844787C7.8034 0.746863 8.00383 0.695557 8.20756 0.695557C8.41128 0.695557 8.61172 0.746863 8.79037 0.844787L15.4154 4.74043C15.4992 4.79027 15.5686 4.86102 15.6168 4.94577C15.665 5.03052 15.6903 5.12636 15.6903 5.22386C15.6903 5.32136 15.665 5.41716 15.6168 5.50191C15.5686 5.58667 15.4992 5.65745 15.4154 5.7073L8.79037 9.6029C8.61172 9.70082 8.41128 9.75217 8.20756 9.75217C8.00383 9.75217 7.8034 9.70082 7.62475 9.6029V9.6029Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.8237 6.61719L15.3862 7.52722C15.47 7.57706 15.5394 7.64781 15.5876 7.73256C15.6358 7.81732 15.6611 7.91316 15.6611 8.01066C15.6611 8.10815 15.6358 8.20396 15.5876 8.28871C15.5394 8.37346 15.47 8.44425 15.3862 8.49409L8.76123 12.4185C8.58259 12.5164 8.38215 12.5677 8.17842 12.5677C7.9747 12.5677 7.77422 12.5164 7.59558 12.4185L0.970603 8.52282C0.886809 8.47297 0.817424 8.40222 0.769226 8.31747C0.721027 8.23272 0.695679 8.13688 0.695679 8.03938C0.695679 7.94188 0.721027 7.84608 0.769226 7.76133C0.817424 7.67658 0.886809 7.60579 0.970603 7.55594L2.5331 6.64595"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.8237 9.43237L15.3862 10.3424C15.47 10.3922 15.5394 10.463 15.5876 10.5478C15.6358 10.6325 15.6611 10.7283 15.6611 10.8258C15.6611 10.9233 15.6358 11.0191 15.5876 11.1039C15.5394 11.1886 15.47 11.2594 15.3862 11.3092L8.76123 15.2049C8.58259 15.3028 8.38215 15.3541 8.17842 15.3541C7.9747 15.3541 7.77422 15.3028 7.59558 15.2049L0.970603 11.3092C0.886809 11.2594 0.817424 11.1886 0.769226 11.1039C0.721027 11.0191 0.695679 10.9233 0.695679 10.8258C0.695679 10.7283 0.721027 10.6325 0.769226 10.5478C0.817424 10.463 0.886809 10.3922 0.970603 10.3424L2.5331 9.43237"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
